import React from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
// Assets
import LogoImg from "../../assets/svg/Logo";

export default function Contact() {
  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

  return (
    <Wrapper>
      <div style={{ backgroundColor: "#000513" }} className="">
        <div className="container">
          <InnerWrapper
            className="flexSpaceCenter"
            style={{ padding: "30px 0" }}
          >
            <Link
              className="flexCenter animate pointer"
              to="home"
              smooth={true}
              offset={-80}
            >
              <LogoImg fill="#FFF" />
            </Link>
            <StyleP className="whiteColor font13">
              © {getCurrentYear()} -{" "}
              <span
                className="whiteColor font13"
                style={{ fontWeight: "bold" }}
              >
                IronX Global
              </span>{" "}
              All Right Reserved
            </StyleP>

            <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
              <span className="whiteColor font13">
                2BI TECNOLOGIA E SERVIÇOS LTDA – CNPJ 35.165.659/0001-82{" "}
              </span>
            </div>
          </InnerWrapper>
          <InnerWrapper>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "16px",
                justifyContent: "center",
                padding: "1rem 0",
              }}
            >
              <span
                className="whiteColor font13"
                style={{ textAlign: "center" }}
              >
                <a
                  href="tel:+5511969127513"
                  className="underline"
                  style={{
                    textDecoration: "underline",
                    color: "#fff",
                    fontSize: "1rem",
                  }}
                >
                  +55 (11) 96912-7513
                </a>
                <br />
                04035-001 – São Paulo - SP 2BI TECNOLOGIA E SERVIÇOS LTDA – CNPJ
                35.165.659/0001-82
              </span>
            </div>
          </InnerWrapper>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;
const InnerWrapper = styled.div`
  @media (max-width: 550px) {
    flex-direction: column;
  }
`;
const StyleP = styled.p`
  @media (max-width: 550px) {
    margin: 20px 0;
  }
`;
