import React from "react";
import styled from "styled-components";
// Components
import ProjectBox from "../Elements/ProjectBox";
import FullButton from "../Buttons/FullButton";
// Assets
import ProjectImg1 from "../../assets/img/projects/1.png";
import ProjectImg2 from "../../assets/img/projects/2.png";
import ProjectImg3 from "../../assets/img/projects/3.png";
import AddImage2 from "../../assets/img/add/add2.png";

export default function Projects() {
  return (
    <Wrapper id="projects">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1
              style={{
                background: "linear-gradient(90deg, #38D427, #78B180)",
                WebkitBackgroundClip: "text",
                color: "transparent",
              }}
              className="font40 extraBold"
            >
              Mobile Platform
            </h1>
            <p className="font13">
              Trade on the go with our mobile platform, offering real-time
              access to the Forex market.
              <br />
              With leverage of 1:3000, manage large trades with small deposits,
              giving you more control
              <br />
              and flexibility while trading anytime, anywhere.
            </p>
          </HeaderInfo>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg1}
                title="Stay Informed with Real-Time Market Updates and Insights"
                text="Get the latest data and analysis to make informed trading decisions with up-to-the-minute market information."
                action={() => alert("clicked")}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg2}
                title="Trade at Your Convenience: Anytime, Anywhere Access"
                text="Experience the freedom to trade on your terms with our platform, accessible 24/7 from any device."
                action={() => alert("clicked")}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg3}
                title="Execute Orders Instantly with Seamless Mobile Trading"
                text="Place orders quickly and efficiently with our intuitive mobile trading platform designed for fast execution."
                action={() => alert("clicked")}
              />
            </div>
          </div>

          <div className="row flexCenter">
            <div style={{ margin: "50px 0", width: "200px" }}></div>
          </div>
        </div>
      </div>
      <div className="lightBg">
        <div className="container">
          <Advertising className="flexSpaceCenter">
            <AddLeft>
              <AddLeftInner>
                <ImgWrapper className="flexCenter">
                  <img className="radius8" src={AddImage2} alt="add" />
                </ImgWrapper>
              </AddLeftInner>
            </AddLeft>
            <AddRight>
              <h4 className="font15 semiBold">Need Help?</h4>
              <h2
                style={{
                  background: "linear-gradient(90deg, #38D427, #78B180)",
                  WebkitBackgroundClip: "text",
                  color: "transparent",
                }}
                className="font40 extraBold"
              >
                24/7 Support
              </h2>
              <p className="font12">
                IronX Global provides 24/7 support, ensuring our clients receive
                continuous assistance and prompt solutions for any need. Whether
                it's answering questions, resolving technical issues, or
                offering expert guidance, our dedicated support team is always
                available to help, ensuring a smooth and uninterrupted
                experience.
              </p>
              <ButtonsRow
                className="flexNullCenter"
                style={{ margin: "30px 0" }}
              >
                <div style={{ width: "190px" }}>
                  <a
                    href="https://t.me/ironxglobalFX"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FullButton title="Get Started" action={() => {}} />
                  </a>
                </div>
                <div style={{ width: "190px", marginLeft: "15px" }}>
                  <a href="mailto:contact@ironxglobal.com">
                    <FullButton title="Contact Us" action={() => {}} border />
                  </a>
                </div>
              </ButtonsRow>
            </AddRight>
          </Advertising>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  padding: 100px 0;
  margin: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 60px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  position: relative;
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
  }
`;
const AddLeftInner = styled.div`
  width: 100%;
  position: absolute;
  top: -300px;
  left: 0;
  @media (max-width: 1190px) {
    top: -250px;
  }
  @media (max-width: 920px) {
    top: -200px;
  }
  @media (max-width: 860px) {
    order: 1;
    position: relative;
    top: -60px;
    left: 0;
  }
`;
const ImgWrapper = styled.div`
  width: 100%;
  padding: 0 15%;
  img {
    width: 100%;
    height: auto;
  }
  @media (max-width: 400px) {
    padding: 0;
  }
`;
