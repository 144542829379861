import React from "react";
import styled from "styled-components";
// Assets
import RollerIcon from "../../assets/svg/Services/RollerIcon";
import {
  ComputerDesktopIcon,
  GiftIcon,
  RocketLaunchIcon,
  TicketIcon,
} from "@heroicons/react/16/solid";

export default function ServiceBox({ icon, title, subtitle }) {
  let getIcon;

  switch (icon) {
    case "roller":
      getIcon = <RocketLaunchIcon width={48} height={48} color="#78B180" />;
      break;
    case "monitor":
      getIcon = <GiftIcon width={48} height={48} color="#78B180" />;
      break;
    case "browser":
      getIcon = <TicketIcon width={48} height={48} color="#78B180" />;
      break;
    case "printer":
      getIcon = <ComputerDesktopIcon width={48} height={48} color="#78B180" />;
      break;
    default:
      getIcon = <RollerIcon />;
      break;
  }

  return (
    <Wrapper className="flex flexColumn">
      <IconStyle>{getIcon}</IconStyle>
      <TitleStyle className="font20 extraBold">{title}</TitleStyle>
      <SubtitleStyle className="font13">{subtitle}</SubtitleStyle>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;
const IconStyle = styled.div`
  @media (max-width: 860px) {
    margin: 0 auto;
  }
`;
const TitleStyle = styled.h2`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  padding: 40px 0;
  @media (max-width: 860px) {
    padding: 20px 0;
  }
`;
const SubtitleStyle = styled.p`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
`;
